<template>
  <div>
    <page-header :title="this.$t('dictionary.nat')" />
    <page-body>
      <nat-list :id="listId" :hiddenActions="hiddenActions" />
    </page-body>
  </div>
</template>

<script>
import NatList from './components/List'
export default {
  name: 'NatIndex',
  components: {
    NatList,
  },
  data () {
    return {
      listId: 'NatList',
      hiddenActions: [],
    }
  },
  async created () {
    const data = await this.$http.get('/v1/products/product-status?code=nat_gateway')
    if (!data.data) {
      this.hiddenActions.push('create')
    } else {
      this.hiddenActions = []
    }
  },
}
</script>
