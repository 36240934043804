<template>
  <div>
    <!-- <page-header :title="headerTitle" :tabs="cloudEnvOptions" :current-tab.sync="cloudEnv" /> -->
    <page-header :title="headerTitle" :current-tab.sync="cloudEnv" />
    <page-body needMarginBottom>
      <component
        :is="component"
        :type="type"
        ref="formRef"
        @change="dataChange" />
    </page-body>
    <page-footer>
      <template v-slot:right>
        <div class="d-flex align-items-center">
          <div v-if="price.spec"
            class="mr-4 d-flex align-items-center">
            <div class="text-truncate">实例规格</div>
            <div class="ml-2 prices">
              <div class="hour d-flex">
                <div>{{ price.spec }}</div>
              </div>
            </div>
          </div>
          <div
            v-if="fd.address_type === 'internet' && price.instance"
            class="mr-4 d-flex align-items-center">
            <div class="text-truncate">实例费</div>
            <div class="ml-2 prices">
              <div class="hour d-flex">
                <div>{{ price.instance }}</div>
              </div>
            </div>
          </div>
          <div
            v-if="fd.address_type === 'internet' && price.bandwidth"
            class="mr-4 d-flex align-items-center">
            <div class="text-truncate">带宽值</div>
            <div class="ml-2 prices">
              <div class="hour d-flex">
                <div>{{ price.bandwidth }}</div>
              </div>
            </div>
          </div>
          <div
            v-if="fd.address_type === 'internet' && price.traffic"
            class="mr-4 d-flex align-items-center">
            <div class="text-truncate">公网流量费</div>
            <div class="ml-2 prices">
              <div class="hour d-flex">
                <div>{{ price.traffic }}</div>
              </div>
            </div>
          </div>
          <div v-if="price.lcu" class="mr-4 d-flex align-items-center">
            <div class="text-truncate">容量单位</div>
            <div class="ml-2 prices">
              <div class="hour d-flex">
                <div>{{ price.lcu }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="btns-wrapper d-flex align-items-center">
          <a-button
            type="primary"
            class="mr-2"
            @click="submit"
            :loading="loading">{{ $t("common.create") }}</a-button>
          <a-button @click="cancel">{{ $t("network.text_31") }}</a-button>
        </div>
      </template>
    </page-footer>
  </div>
</template>

<script>
import IDC from './form/IDC'
import Public from './form/Public'
import Private from './form/Private'
import { getCloudEnvOptions } from '@/utils/common/hypervisor'
import { numerify } from '@/filters'

export default {
  name: 'LbCreate',
  components: {
    IDC,
    Public,
    Private,
  },
  data () {
    const cloudEnvOptions = getCloudEnvOptions('compute_engine_brands', true)
    const queryType = this.$route.query.type
    let cloudEnv = queryType === 'idc' ? 'onpremise' : this.$route.query.type
    let routerQuery = this.$route.query.type
    if (!cloudEnvOptions.find((val) => val.key === cloudEnv)) {
      cloudEnv = cloudEnvOptions[0].key
      routerQuery = cloudEnv === 'onpremise' ? 'idc' : cloudEnv
    }
    return {
      loading: false,
      cloudEnvOptions,
      cloudEnv,
      routerQuery,
      priceTips: '--',
      currency: '¥',
      price: {
        amount: null,
        currency: null,
        bgp_ip: null,
        bandwidth: null,
        instance: null,
        lcu: null,
        traffic: null,
        spec: null,
      },
      fd: null,
    }
  },
  computed: {
    type () {
      const { type = 'idc' } = this.$route.query
      switch (type) {
        case 'private':
          return 'private'
        case 'public':
          return 'public'
        default:
          return 'idc'
      }
    },
    component () {
      const { type = 'idc' } = this.$route.query
      switch (type) {
        case 'private':
          return 'Private'
        case 'public':
          return 'Public'
        default:
          return 'IDC'
      }
    },
    headerTitle () {
      const res = this.$t('network.text_137')
      return this.$t('compute.text_1161', [res])
    },
  },
  watch: {
    cloudEnv (val) {
      this.$nextTick(() => {
        const query = this.getQuery(this.$router.history.current.query)
        const path = this.$router.history.current.path
        const newQuery = JSON.parse(JSON.stringify(query))
        newQuery.type = val === 'onpremise' ? 'idc' : val
        this.$router.push({ path, query: newQuery })
      })
    },
  },
  created () {
    if (this.routerQuery !== this.$route.query.type) {
      this.$router.push({
        path: this.$router.history.current.path,
        query: {
          type: this.routerQuery,
        },
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener('popstate', this.popstate)
  },
  methods: {
    getQuery (query) {
      if (query.sence === 'image') {
        return { type: query.type }
      }
      return query
    },
    async submit () {
      this.loading = true
      try {
        const data = await this.$refs.formRef.submit()
        data.area_mapping_id = data.cloudregion
        delete data.cloudregion
        await new this.$Manager('loadbalancers').create({ data })
        this.loading = false
        this.$message.success(this.$t('network.text_290'))
        this.cancel()
      } catch (error) {
        this.loading = false
        throw error
      }
    },
    cancel () {
      this.$router.push('/lb')
    },
    dataChange (values) {
      this.fd = values
      this._getPrice(values)
    },
    priceFormat1 (price, unit) {
      const str = price.toString()
      const ary = str.split('.')
      let len = 0
      if (ary.length > 1) {
        len = ary[1].length
      }
      let u = '0,0.00'
      if (len > 2) {
        for (let i = 0; i < len - 2; i++) {
          u += '0'
        }
      }
      price = numerify(price, u)

      return `${this.currency} ${price}${unit}`
    },
    async _getPrice (values) {
      const {
        cloudregion,
        charge_type,
        bandwidth,
        address_type,
        loadbalancer_spec,
      } = values
      if (!cloudregion || typeof cloudregion === 'object' || !charge_type || !address_type || !loadbalancer_spec) return
      if ((charge_type === 'bandwidth' && bandwidth) || charge_type === 'traffic') {
        const params = {
          area_mapping_id: cloudregion,
          charge_type: charge_type,
          bandwidth: bandwidth,
          address_type: address_type,
          spec: loadbalancer_spec,
        }
        new this.$Manager('loadbalancer_prices/lbs-price', 'v2')
          .list({
            params: params,
          })
          .then((res) => {
            this.price.spec = res.data.spec ? this.priceFormat1(
              res.data.spec,
              '/小时',
            ) : 0
            this.price.instance = res.data.instance ? this.priceFormat1(
              res.data.instance,
              '元/个/小时',
            ) : 0
            this.price.lcu = res.data.lcu ? this.priceFormat1(res.data.lcu, '元/LCU') : 0
            this.price.traffic = res.data.traffic ? this.priceFormat1(res.data.traffic, '/GB') : 0
            this.price.bandwidth = res.data.bandwidth ? this.priceFormat1(
              res.data.bandwidth,
              '/小时',
            ) : 0
          })
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../../../src/styles/less/theme";

.prices {
  .hour {
    color: @error-color;
    font-size: 24px;
  }
  .tips {
    color: #999;
    font-size: 12px;
  }
}
</style>
